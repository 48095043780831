import React from 'react'

import Image from '../Image'
import Button from '../Button'
import useViewport from '../../hooks/useViewport'

import styles from './Hero.module.scss'

const Hero = ({
  title,
  description,
  buttons,
  image
}) => {
  const viewport = useViewport()

  return (
    <div className={styles.hero}>
      <div className={styles.heroLeft}>
        <h1 className={styles.heroTitle}>{title}</h1>
        <h2 className={styles.heroDescription}>{description}</h2>
        {viewport.width > 1023 && (buttons || []).length > 0 &&
          <div className={styles.heroButtons}>
            {buttons.map((x, idx) => (
              <Button key={idx} className={styles.heroButton} title={x.title} icon={x.icon} link={x.link} external={x.external} onClick={x.onClick} />
            ))}
          </div>
        }
      </div>
      <div className={styles.heroRight}>
        <div className={styles.heroImage}>
          <Image src={image} width={603} height={426} alt="" priority />
        </div>
      </div>
      {viewport.width < 1024 && (buttons || []).length > 0 &&
        <div className={styles.heroButtons}>
          {buttons.map((x, idx) => (
            <Button key={idx} className={styles.heroButton} title={x.title} icon={x.icon} link={x.link} external={x.external} onClick={x.onClick} />
          ))}
        </div>
      }
    </div>
  )
}

export default Hero