import { faComment, faHandHoldingHeart, faHeart, faUser, faUserFriends, faUserGraduate, faUsers } from "@fortawesome/free-solid-svg-icons"
import ContentBlock from "../../components/ContentBlock"
import ItemSection from "../../components/ItemSection"
import { CircleStepItem } from "../../components/StepsCircle"

export default function WeOffer({
  backgroundColour
}: {
  backgroundColour?: string
}) {
  return (
    <ContentBlock 
      left={{
        type: 'text',
        title: 'We Offer',
        content: (
          <ItemSection
            backgroundColour={backgroundColour || '#ffffff'}
            itemBackgroundColour={backgroundColour || '#ffffff'}
            size={3}
            leftAlign
            noPadding
            addContainer={false}
            itemsTopPadding={30}
            items={[
              <CircleStepItem
                key="user"
                title="Support"
                content="Support for the person experiencing problem gambling, whether their aim is to stop gambling completely, or to control and better manage their gambling behaviour."
                icon={faUser}
                link="/gambling-harms-treatment"
              />,
              <CircleStepItem
                key="family"
                title="Family"
                content="Support for anyone affected by gambling behaviour (family member, friend, colleague)"
                icon={faUsers}
                link="/family-and-friends"
              />,
              <CircleStepItem
                key="couple"
                title="Couples"
                content="Support to couples after they have completed treatment."
                icon={faUserFriends}
              />,
              <CircleStepItem
                key="online"
                title="Women"
                content="Weekly drop-in online support group for women who gamble and for women affected by someone’s gambling. (WINGS)"
                icon={faHeart}
                link="/support-groups#wings"
              />,
              <CircleStepItem
                key="aftercare"
                title="Aftercare"
                content="Aftercare groups to those who have successfully stopped gambling and require extra supported learning following treatment. (Green Shoots Recovery)"
                icon={faComment}
                link="/support-groups#green-shoots"
              />,
              <CircleStepItem
                key="peer"
                title="Peer Support"
                content="A Breakeven counsellor who uses his lived experience to inspire hope for those starting and continuing their journey of recovery."
                icon={faHandHoldingHeart}
              />,
              <CircleStepItem
                key="training"
                title="Training"
                content="We also deliver a range of training and workshop options to increase awareness of gambling harms, support options available and reduce stigmas attached to those gambling and affected others."
                icon={faUserGraduate}
                link="/professionals/education-and-training"
              />
            ]}
          />
        )
      }}
      isGroupEnd
      isGroupStart
      backgroundColour={backgroundColour || '#ffffff'}
    />
  )
}