import React, { useState, useRef, useEffect } from 'react'

import styles from './Testimonials.module.scss'

export type TestimonialInfo = {
  quote: string
  name?: string
  description?: string
  image?: string
}

const testimonialData: Array<TestimonialInfo> = [{
  quote: 'I wish I had taken this step years ago. This was the turning point in my life for me and I’m eternally grateful to you for the help and support you have given me.',
}, {
  quote: 'On Tuesday it will be six months since I last gambled in any way whatsoever. There was a time when this landmark would’ve meant something thing to me and I would’ve used terms like ‘clean’ or ‘one day at a time’. Not so much now though. Now I barely think about it at all and the only reason it’s on my mind was overhearing a mess room conversation earlier where two blokes were talking about their gambling habits. It genuinely made me feel sad for them and I had yet another moment where I thought ‘oh yeah! I don’t do that anymore do I?’.',
}, {
  quote: 'I’ll not get complacent or pretend I’m all fixed up but I’m the best I’ve been in decades.',
}, {
  quote: 'I’m a work in progress, still bad with money (and a few other things) but each issue will be sorted in time. Gambling had to be number one and now it’s time to move on.',
}, {
  quote: 'The support from my wife has been more than any man deserves and I just don’t know how to express what she means to me. The work I did with a counsellor/therapist was life changing and I hope I can one day repay a tiny part of what  I feel I owe her and breakeven as an organisation by sharing my ‘lived experience’ at a seminar in the future’.',
}, {
  quote: "Veronica is an amazing person who takes the time to understand your situation, how you are feeling, makes you feel comfortable and in a safe environment and to help you understand how you are feeling and why you are feeling this way. I'm 31 years old and gambling has been a problem in my life for 15 years but I have now understand the relationship I have with this and how my brain functions. Veronica listens but gives honest feedback which I always take away and implement. I would highly recommend Veronica or the breakeven team service to everybody I can, I have relapsed and have been made to feel welcome and not awful for undoing the hard work, it's a support service. As a summary the whole charity is amazing but Veronica is a massive credit and I can't thank her enough for everything she has done for me."
}, {
  quote: "Jay was amazing & so supportive & really listened. Jay was also good at letting me know I was doing ALL I could to support my son going through his addiction."
}, {
  quote: "I cant thank Alex and Breakeven enough, a truly fantastic service that I hope I will never need to use again, but its comforting to know if I struggle that I am able to reach out again. Alex has been simply amazing and has helped me readjust my thought processes and help put me on a path that will allow a more positive life for me and my children. Thank you again. Outstanding service."
}]

const Testimonials = ({
  backgroundColour,
  itemBackgroundColour,
  data,
  addContainer = true,
  noPadding,
  noItemPadding,
  title
}: {
  backgroundColour?: string
  itemBackgroundColour?: string
  data?: Array<TestimonialInfo>
  addContainer?: boolean
  noPadding?: boolean
  noItemPadding?: boolean
  title?: string
}) => {
  const [current, setCurrent] = useState(0)
  const [isChanging, setIsChanging] = useState(false)

  const currentRef = useRef<number>(0)
  const intervalRef = useRef<NodeJS.Timeout>()
  
  useEffect(() => {
    startAutoChange()

    return () => {
      stopAutoChange()
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsChanging(true)
    }, 10)

    setTimeout(() => {
      setIsChanging(false)

      currentRef.current = current
    }, 300)
  }, [current])

  const startAutoChange = () => {
    intervalRef.current = setInterval(() => {
      goToNext()
    }, 10000)
  }

  const stopAutoChange = () => {
    clearInterval(intervalRef.current)
  }

  const goToNext = () => {
    setCurrent(prev => {
      if (prev < ((data ?? testimonialData).length - 1)) {
        return prev + 1
      }

      return 0
    })
  }

  const goToPrev = () => {
    setCurrent(prev => {
      if (prev > 0) {
        return prev - 1
      }

      return 0
    })
  }

  const handleOnChange = newIdx => {
    setCurrent(newIdx)

    stopAutoChange()
    startAutoChange()
  }

  return (
    <div className={styles.testimonials} style={{
      background: backgroundColour || '#FFFFFF',
      ...(noPadding ? {
        padding: 0
      } : {})
    }}>
      <div className={`${addContainer ? 'container': ''}`}>
        <div className={styles.testimonialsContainer} style={{
          background: itemBackgroundColour || '#F1F1F1',
          ...(noItemPadding ? {
            borderRadius: 0
          } : {})
        }}>
          <div className={styles.testimonialsCurrentItem}>
            <TestimonialItem 
              title={title} 
              data={(data ?? testimonialData)?.[current]} 
              active={current} 
              dotCount={(data ?? testimonialData)?.length ?? 0} 
              onChange={handleOnChange}
              noPadding={noItemPadding}
            />
          </div>
          {current !== currentRef.current &&
            <div className={[styles.testimonialsPrevItem, isChanging ? styles.testimonialItemChanging : ''].join(' ')}>
              <TestimonialItem 
                title={title} 
                data={(data ?? testimonialData)?.[currentRef.current]} 
                active={current} 
                onChange={handleOnChange}
                noPadding={noItemPadding}
              />
            </div>
            }
        </div>
      </div>
    </div>
  )
}

const TestimonialItem = ({
  data,
  active,
  onChange,
  dotCount = 0,
  title,
  noPadding
}: {
  data?: TestimonialInfo
  active: number
  onChange?: (idx: number) => void
  dotCount?: number
  title?: string
  noPadding?: boolean
}) => {
  return (
    <div className={styles.testimonialItem}>
      <div className={styles.testimonialItemLeft} style={noPadding ? {
        padding: 0
      } : {}}>
        {title &&
          <div className={styles.testimonialItemTitle}>{title || 'TESTIMONIAL'}</div>
        }
        <div className={`${styles.testimonialItemQuote} ${(data?.quote?.length ?? 0) > 400 ? styles.testimonialItemQuoteXSmall : ((data?.quote?.length ?? 0) > 300 ? styles.testimonialItemQuoteSmall : '')}`}>“{data?.quote}“</div>
        {data?.name &&
          <div className={styles.testimonialItemName}>{data.name}</div>
        }
        {data.description && data.description.length > 0 &&
          <div className={styles.testimonialItemDescription}>{data.description}</div>
        }
        <div className={styles.testimonialItemDots}>
          {(new Array(dotCount)).fill(0).map((x, idx) => (
            <div key={idx} className={styles.testimonialItemDotContainer} onClick={() => onChange(idx)}>
              <div className={[styles.testimonialItemDot, idx === active ? styles.testimonialItemDotActive : ''].join(' ')} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Testimonials